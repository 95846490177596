import React from 'react';
import { NextPage } from 'next';
import { InfoBlock, SectionLabel, ServiceCard, ShipInfo } from 'templates/main';
import { bgMainHeader } from 'images/main';
import { Hero, SEO } from 'templates/layout';
import { Box, Container, Grid, makeStyles, Theme, useMediaQuery, withStyles } from '@material-ui/core';
import { QUOTE_ROUTE } from 'data/routes';
import { animated, useSpring } from 'react-spring';
import { animationCard } from 'utils/animations/main';
import { infoBlocks, serviceCards } from 'data/page-data/main-page-data';

const useHeroStyles = makeStyles((theme: Theme) => ({
  wrapperHero: {
    minHeight: 740,
    [theme.breakpoints.up('md')]: {
      minHeight: 820,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 860,
    },
  },
  cardSection: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));

const GridContainer = withStyles((theme: Theme) => ({
  root: {
    marginTop: 'calc(-240px + 40px)',
    [theme.breakpoints.up(375)]: {
      marginTop: theme.spacing(-43), // calc(-255px + 40px) for 375 and sm
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-30),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-14), // calc(-85px + 15px) for md and lg
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-117px',
    },
  },
}))(Grid);

const IndexPage: NextPage = () => {
  const isMediumUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const classes = useHeroStyles();
  const fadeIn = (ms: number) => useSpring(animationCard(ms));

  return (
    <>
      <SEO
        title={'Move your freight the right way'}
        description={
          'Discover the right logistics solutions that will allow you to move your freight asap and with no hassle whatsoever.'
        }
      />
      <Hero
        className={classes.wrapperHero}
        title={
          <>
            <span>LOGISTICS</span> <br /> MADE SIMPLE
          </>
        }
        buttonText={'Get a Quote'}
        text={'Get your freight fast and safely across the U.S.'}
        image={bgMainHeader}
        link={QUOTE_ROUTE}
      />
      <Container maxWidth={false} fixed>
        <GridContainer spacing={isMediumUp ? 6 : 2} container justify="center">
          {serviceCards.map(({ delay, buttonText, link, icon, title }, index) => (
            <Grid key={index} md={4} sm={6} xs={12} item>
              <animated.div style={fadeIn(delay)}>
                <ServiceCard icon={icon} link={link} title={title} buttonText={buttonText} />
              </animated.div>
            </Grid>
          ))}
        </GridContainer>
      </Container>
      <SectionLabel />
      <Box className={classes.cardSection}>
        {infoBlocks.map(({ buttonText, image, imagePosition, link, text, title }, index) => (
          <InfoBlock
            key={index}
            image={image}
            title={title}
            text={text}
            link={link}
            buttonText={buttonText}
            imagePosition={imagePosition}
          />
        ))}
      </Box>
      <ShipInfo />
    </>
  );
};

export default IndexPage;
