import React, { FC, ReactNode } from 'react';
import { Box, Button, Card, makeStyles, Theme, Typography } from '@material-ui/core';
import Link from 'next/link';

interface ServiceCardProps {
  icon: ReactNode;
  title: string | ReactNode;
  link: string;
  buttonText: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2, 4),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 8),
    },
  },
  title: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      fontSize: 18,
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2),
      fontSize: 14,
    },
  },
  iconBox: {
    flexDirection: 'row',
    '& svg': {
      maxWidth: 50,
      height: 50,
      [theme.breakpoints.up(375)]: {
        maxWidth: 55,
        height: 55,
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 65,
        height: 65,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 72,
        height: 72,
      },
    },
  },
}));

const ServiceCard: FC<ServiceCardProps> = ({ title, icon, link, buttonText }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" className={classes.iconBox}>
          {icon} <Typography className={classes.title}>{title}</Typography>
        </Box>
        <Link href={link}>
          <a>
            <Button className={classes.button} variant="outlined" size="small" color="primary">
              {buttonText}
            </Button>
          </a>
        </Link>
      </Box>
    </Card>
  );
};

export default ServiceCard;
