import React, { FC, useState } from 'react';
import { Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import { SpacingLayout } from 'components/atoms';
import IsVisible from 'react-visibility-sensor';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    opacity: 0,
    position: 'relative',
    maxWidth: '85%',
    display: 'block',
    margin: '0 auto',
    '&::before': {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      content: "''",
      margin: '0 auto',
      position: 'absolute',
      top: '43%',
      left: 0,
      right: 0,
      bottom: 0,
      width: '95%',
      zIndex: '-1',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 465,
    },
  },
  bgWhite: {
    background: '#fff',
    padding: '0 15px',
    textTransform: 'uppercase',
  },
  title: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  fadeIn: {
    animation: '$fadeIn 800ms forwards ease-in',
    '&::before': {
      animation: '$fadeInAfter 800ms forwards ease-in',
    },
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '40%': { opacity: 1 },
    '100%': { opacity: 1 },
  },
  '@keyframes fadeInAfter': {
    '0%': { width: 0 },
    '40%': { width: 0 },
    '100%': { width: '95%' },
  },
}));

const SectionLabel: FC = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  return (
    <SpacingLayout textAlign="center">
      <IsVisible onChange={(active) => active && setIsVisible(true)}>
        <>
          <Typography color="primary" className={clsx(classes.subtitle, isVisible && classes.fadeIn)}>
            <span className={classes.bgWhite}>Patron trucking</span>
          </Typography>
          <Typography data-aos="flip-up" data-aos-delay="500" variant="h2" component="h2" className={classes.title}>
            The <span>right choice</span> for your
            <Hidden mdUp>
              <br />
            </Hidden>
            business
          </Typography>
        </>
      </IsVisible>
    </SpacingLayout>
  );
};

export default SectionLabel;
