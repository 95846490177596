import React, { ReactNode } from 'react';
import { cityPicture, truck1, truck2 } from 'images/main';
import { ABOUT_US_ROUTE, CARRIERS_ROUTE, CONTACT_US_ROUTE, QUOTE_ROUTE, SERVICES_ROUTE } from 'data/routes';
import { ExpertIcon, HandshakeIcon, TruckInfoIcon } from 'svgs';

export interface InfoBlockProps {
  image: string;
  title: string;
  text: string;
  buttonText: string;
  imagePosition?: 'top-left' | 'top-right' | 'left-bottom';
  link: string;
}

export interface ServiceCardProps {
  icon: ReactNode;
  title: string | ReactNode;
  link: string;
  buttonText: string;
}

interface IServiceCard extends ServiceCardProps {
  delay: number;
}

export const infoBlocks: InfoBlockProps[] = [
  {
    image: truck1,
    title: 'We take responsibility for all processes related to Freight Transportation',
    text:
      'We undertake the completion of all tasks at each stage of the complex, multi-component freight transportation process. You do not need to enter into contracts with many companies responsible for individual links of the supply chain',
    link: QUOTE_ROUTE,
    buttonText: 'Get a Quote',
    imagePosition: 'top-left',
  },
  {
    image: cityPicture,
    title: 'We optimize your staff costs',
    text:
      'You do not need to increase your staff to control contractors if you have new logistics directions in different regions of the country. Our experts will facilitate reliable forwarding of your goods in one go.',
    link: ABOUT_US_ROUTE,
    buttonText: 'About Us',
    imagePosition: 'top-right',
  },
  {
    image: truck2,
    title: 'We work with a vast network of partners throughout the U.S.',
    text:
      "For any logistics direction, we ensure the timely implementation of agreements and the safety of your goods. Our trusted agents will ensure your freight's security and compliance with all contracts signed by the customer.",
    link: CARRIERS_ROUTE,
    buttonText: 'Carriers',
    imagePosition: 'left-bottom',
  },
];

export const serviceCards: IServiceCard[] = [
  {
    icon: <TruckInfoIcon width="100%" viewBox="0 0 72 72" />,
    link: SERVICES_ROUTE,
    title: (
      <>
        Logistic <br /> Services
      </>
    ),
    buttonText: 'View now',
    delay: 800,
  },
  {
    icon: <ExpertIcon width="100%" viewBox="0 0 72 72" />,
    link: CARRIERS_ROUTE,
    title: (
      <>
        Become <br /> a Carrier
      </>
    ),
    buttonText: 'Find your load',
    delay: 950,
  },
  {
    icon: <HandshakeIcon width="100%" viewBox="0 0 72 72" />,
    link: CONTACT_US_ROUTE,
    title: (
      <>
        Expert <br /> Advice
      </>
    ),
    buttonText: 'Get in touch',
    delay: 1050,
  },
];
