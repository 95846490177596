import React, { FC, ReactNode, useRef } from 'react';
import { Box, Container, Grid, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { shipBg } from 'images/main';
import { BoxIcon, CalendarIcon, EmployeeIcon, GlobeIcon } from 'svgs';
import { useParallax } from 'hooks';
import { animated } from 'react-spring';

interface IconItemProps {
  icon: ReactNode;
  title: string;
  subtitle: string;
  hintText?: string;
  delay: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    background: 'linear-gradient(0deg, rgba(0, 26, 85, 0.8), rgba(0, 26, 85, 0.8))',
    backgroundSize: 'cover',
    position: 'relative',
  },
  title: {
    color: 'white',
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(9),
    marginBottom: theme.spacing(12),
  },
  boxItem: {
    marginBottom: theme.spacing(17),
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  imageBackground: {
    position: 'absolute',
    top: '0%',
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: 'cover',
    zIndex: -1,
    width: '100%',
    height: '150%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '150%',
      top: '5%',
    },
  },
  hintText: {
    fontWeight: 300,
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
}));
const calcAnimation = (o) => `translateY(-${o * 0.03}px)`;
const ShipInfo: FC = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { offset } = useParallax(ref);
  const isMediumUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <section ref={ref} className={classes.section}>
      <animated.img
        style={{ transform: offset.to(calcAnimation) }}
        className={classes.imageBackground}
        src={shipBg}
        alt={shipBg}
      />
      <div data-aos="zoom-in">
        <Typography variant="h2" component="h2" className={classes.title}>
          <span>Briefly</span> and to the <span>point</span>
        </Typography>
      </div>
      <Container>
        <Grid container>
          <IconItem icon={<CalendarIcon />} title={'2016'} subtitle={'Year Founded'} delay={isMediumUp ? 0 : 300} />
          <IconItem icon={<EmployeeIcon />} title={'25+'} subtitle={'Employees'} delay={isMediumUp ? 0 : 500} />
        </Grid>
        <Grid container>
          <IconItem icon={<GlobeIcon />} title={'50+'} subtitle={'Carrier Network'} delay={isMediumUp ? 0 : 300} />
          <IconItem
            icon={<BoxIcon />}
            title={'1000+'}
            subtitle={'Loads Moved'}
            hintText={'Last year'}
            delay={isMediumUp ? 0 : 400}
          />
        </Grid>
      </Container>
    </section>
  );
};

const IconItem: FC<IconItemProps> = ({ hintText, delay, title, icon, subtitle = false }) => {
  const classes = useStyles();

  return (
    <Grid md={6} sm={12} xs={12} item>
      <div data-aos="fade-up" data-aos-delay={delay}>
        <Box className={classes.boxItem} display="flex">
          {icon}
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            marginLeft={6}
            minWidth="200px"
            style={{ color: 'white' }}
          >
            <Typography variant="h2" component="h2" className={classes.subtitle}>
              <span>{title}</span>
            </Typography>
            <Typography variant="h3">{subtitle}</Typography>
            {hintText && <Typography className={classes.hintText}>{hintText}</Typography>}
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export default ShipInfo;
