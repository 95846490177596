import React, { FC, useRef } from 'react';
import { Box, Button, Card, Container, Grid, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { Separator } from 'components/atoms';
import clsx from 'clsx';
import Link from 'next/link';
import { animated } from 'react-spring';
import { useParallax } from 'hooks';

interface InfoBlockProps {
  image: string;
  title: string;
  text: string;
  buttonText: string;
  imagePosition?: 'top-left' | 'top-right' | 'left-bottom';
  link: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(30),
    },
  },
  wrapperMargin: {
    paddingTop: theme.spacing(15),
  },
  card: {
    position: 'relative',
    marginTop: theme.spacing(-10),
    margin: theme.spacing(0, 4),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(-20, 0, 0),
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-80, 0, 0),
    },
  },
  textCard: {
    padding: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8),
    },
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.44,
  },
  text: {
    lineHeight: '32px',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(12),
    },
  },
  square: {
    position: 'absolute',
    background: theme.palette.primary.main,
    zIndex: -1,
  },
  cardUpperSquare: {
    width: 285,
    height: 245,
    top: theme.spacing(-3),
    right: theme.spacing(-4),
    [theme.breakpoints.up('md')]: {
      right: theme.spacing(-3),
    },
  },
  cardBottomSquare: {
    width: 200,
    height: 240,
    left: theme.spacing(-4),
    bottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      left: theme.spacing(-20),
    },
  },
  cardUpperLeftSquare: {
    width: 280,
    height: 330,
    top: 'auto',
    bottom: theme.spacing(-3),
    left: theme.spacing(-4),
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(-3),
      left: theme.spacing(-3),
      bottom: 'auto',
    },
  },
  cardBottomLeftSquare: {
    width: 480,
    height: 340,
    right: theme.spacing(5),
    top: theme.spacing(6),
    bottom: 'auto',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      width: 620,
      height: 420,
      right: theme.spacing(3),
      top: theme.spacing(3),
      bottom: 'auto',
    },
  },
  cardTop: {
    marginTop: theme.spacing(-10),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-110),
    },
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: 470,
  },
}));

const calcSquares = (o) => `translateY(${o * 0.007}px)`;
const calcImage = (o) => `translateY(-${o * 0.005}px)`;
const calcCard = (o) => `translateY(${o * 0.005}px)`;

const InfoBlock: FC<InfoBlockProps> = ({ image, text, title, imagePosition = 'top-left', link, buttonText }) => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const ref = useRef(null);
  const { offset } = useParallax(ref);

  const TextCard: FC = () => (
    <animated.div style={{ transform: !isSmall && offset.to(calcCard) }}>
      <Card data-aos="zoom-in" data-aos-delay="100" data-aos-anchor-placement="bottom-bottom" className={classes.textCard}>
        <Typography variant="h3" component="h3" className={classes.title}>
          {title}
        </Typography>
        <Separator my={3} />
        <Typography className={classes.text}>{text}</Typography>
        <Link href={link}>
          <a>
            <Button variant="contained" color="primary">
              {buttonText}
            </Button>
          </a>
        </Link>
      </Card>
    </animated.div>
  );
  const topRight = imagePosition === 'top-right';
  const topLeft = imagePosition === 'top-left';
  const leftBottom = imagePosition === 'left-bottom';

  return (
    <div ref={ref}>
      <Box className={clsx(classes.wrapper, leftBottom && classes.wrapperMargin)}>
        <Container disableGutters={isSmall} fixed>
          <Grid direction={topRight ? 'row-reverse' : 'row'} container>
            <Grid md={7} sm={12} item>
              <animated.div style={{ transform: offset.to(calcImage) }}>
                <img data-aos="zoom-in" className={classes.image} src={image} alt={image} />
              </animated.div>
            </Grid>
            <Grid item md={6} sm={12} />
            <Grid className={clsx(classes.card, leftBottom && classes.cardTop)} item md={6}>
              <animated.div
                data-aos="zoom-in"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-delay="400"
                className={clsx(
                  classes.square,
                  topRight && classes.cardUpperLeftSquare,
                  leftBottom && classes.cardBottomLeftSquare,
                  topLeft && classes.cardUpperSquare,
                )}
                style={{ transform: offset.to(calcSquares) }}
              />
              {topLeft && (
                <animated.div
                  data-aos="zoom-in"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-delay="500"
                  className={clsx(classes.square, classes.cardBottomSquare)}
                  style={{ transform: offset.to(calcSquares) }}
                />
              )}
              <TextCard />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default InfoBlock;
