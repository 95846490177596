import * as d3 from 'd3-ease';

export const animationCard = (delay: number) => ({
  from: { opacity: 0, transform: 'translate3d(0px, 40px, 0)' },
  to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  config: {
    duration: 350,
    easing: (t) => d3.easeCubicOut(t),
  },
  delay,
});
